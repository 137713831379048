import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reserVationChartData: {},
  ownerActivity: null,
  customerData: null,
  allRecordOfCustomerData: null,
  totalStateMents: null,
  statementData: {
    items: [],
    currentPage: 0,
    perPage: parseInt(process.env.REACT_APP_PER_PAGE),
    pageCount: 0,
  },
};

const todoSlice = createSlice({
  name: "Slice_Data",
  initialState,
  reducers: {
    getTheReservationChartData: (state, action) => {
      state.reserVationChartData = action.payload;
    },
    getTheOwnerActivity: (state, action) => {
      state.ownerActivity = action.payload;
    },
    getTheCustomerData: (state, action) => {
      state.customerData = action.payload;
    },
    getTheAllCustomerRecords: (state, action) => {
      state.allRecordOfCustomerData = action.payload;
    },

    fetchStatementData: (state, action) => {
      const { statementData } = state;
      statementData.items = action.payload;
    },
    setCurrentStatementPage: (state, action) => {
      state.statementData.currentPage = action.payload;
    },
  },
});

export const {
  getTheAllCustomerRecords,
  getTheReservationChartData,
  getTheOwnerActivity,
  getTheCustomerData,
  fetchStatementData,
  setCurrentStatementPage,
} = todoSlice.actions;

export default todoSlice.reducer;
