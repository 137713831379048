import React from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Row,
} from "react-bootstrap";
import { whitelogo } from "../assets/images";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { auth } from "../api/Firebase/firebase.config";
import { sendPasswordResetEmail } from "firebase/auth";
import jsonData from "../utils/translation.json";

export const ForgotPassword = ({ isSignedIn, setIsSignedIn }) => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async (values) => {
      sendPasswordResetEmail(auth, values.email)
        .then((res) => {
          return navigate("Login");
        })
        .catch((error) => {
          return error;
        });
    },
  });

  return (
    <>
      <section className="login min-h-screen">
        <Container className="text-center">
          <Image src={whitelogo} fluid className="py-32" />
          <Row>
            <Col lg={5} className="mx-auto">
              <Card className="rounded-5 mb-10">
                <Card.Header className="font-bold text-2xl">
                  {jsonData.LoginForm.recoverPassword}
                </Card.Header>
                <Card.Text className="px-5">
                  {jsonData.LoginForm.recoverPasswordSubTitile}
                </Card.Text>
                <Card.Body className=" py-10">
                  <Form
                    className="mx-auto"
                    onSubmit={formik.handleSubmit}
                    style={{ maxWidth: 277 }}
                  >
                    <div className="px-5">
                      <Form.Control
                        className="mb-10 input-control"
                        type="email"
                        name="email"
                        placeholder={jsonData.Common.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        required
                      />

                      <div className="form-field-error-text text-danger">
                        {formik.touched.email && formik.errors.email ? (
                          <div>{formik.errors.email}</div>
                        ) : null}
                      </div>
                    </div>
                    <Button
                      type="submit"
                      // to="my_property"
                      className="w-full mt-20 btn btn-primary"
                    >
                      {jsonData.LoginForm.connection}
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
