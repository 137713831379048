import React from "react";
import { Navigate } from "react-router-dom";

const PublicRoute = ({ isSignedIn, children }) => {
  if (isSignedIn) {
    return <Navigate to="/my-property" replace />;
  }
  return children;
};

export default PublicRoute;
