import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { firebaseConfig } from "../middleware";
import { collection, getFirestore } from "firebase/firestore";

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const profileCollection = collection(db, "users");
export const reservationCollection = collection(db, "reservationDetails");
