import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import {
  MyProperty,
  CustomerReport,
  Login,
  Reports,
  Statements,
  ForgotPassword,
} from "../pages";

import PublicRoute from "./PublicRoute";

const MainRouter = () => {
  const [isSignedIn, setIsSignedIn] = useState(
    localStorage.getItem("isLoggedIn") ? true : false
  );
  const navigate = useNavigate();

  useEffect(() => {
    const isLogin = localStorage.getItem("isLoggedIn") ? true : false;
    setIsSignedIn(isLogin);
  }, [navigate]);

  return (
    <Routes>
      <Route
        path="/my-property"
        element={
          <PrivateRoute isSignedIn={isSignedIn}>
            <MyProperty />
          </PrivateRoute>
        }
      />
      <Route
        path="/customer-report"
        element={
          <PrivateRoute isSignedIn={isSignedIn}>
            <CustomerReport />
          </PrivateRoute>
        }
      />
      <Route
        path="/reports"
        element={
          <PrivateRoute isSignedIn={isSignedIn}>
            <Reports />
          </PrivateRoute>
        }
      />
      <Route
        path="/statements"
        element={
          <PrivateRoute isSignedIn={isSignedIn}>
            <Statements />
          </PrivateRoute>
        }
      />
      <Route
        path="/login"
        element={
          <PublicRoute isSignedIn={isSignedIn}>
            <Login setIsSignedIn={setIsSignedIn} />
          </PublicRoute>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <PublicRoute isSignedIn={isSignedIn}>
            <ForgotPassword
              setIsSignedIn={setIsSignedIn}
              isSignedIn={isSignedIn}
            />
          </PublicRoute>
        }
      />
      <Route
        path="*"
        element={<Navigate to={isSignedIn ? "/my-property" : "/login"} />}
      />
    </Routes>
  );
};

export default MainRouter;
