import { lazy } from "react";
import { ForgotPassword } from "./ForgotPassword";

const MyProperty = lazy(() => import("./myProperty"));
const CustomerReport = lazy(() => import("./customerReport"));
const Login = lazy(() => import("./login"));
const Reports = lazy(() => import("./reports"));
const Statements = lazy(() => import("./statements"));
export {
  MyProperty,
  CustomerReport,
  Login,
  Reports,
  Statements,
  ForgotPassword,
};
